import React from "react"
import { navigate } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./font-awesome"

import Layout from "./layout"
import SEO from "./seo"
import ReactMarkdown from "react-markdown"

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share"

import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const NewsPage = ({ data }) => {
  let shareUrl = ""
  if (typeof window !== "undefined") {
    shareUrl = window.location.href
  }

  return (
    <Layout>
      <SEO title={`${data.allStrapiNovelties.nodes[0].title} - ICDA`} />
      <section className="mb-3">
        {data.allStrapiNovelties.nodes.map(novelties => (
          <div className="container">
            <div className="my-4">
              <div className="d-flex">
                <button
                  onClick={() => navigate(-1)}
                  className="btn btn-link ml-auto mr-n2"
                >
                  <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />{" "}
                  Volver
                </button>
              </div>
              <div className="bg-sec px-2 py-1 text-white mb-3 fit-content">
                {novelties.novelties_categories.name}
              </div>
              <h4 className="mb-2">{novelties.title}</h4>
              <ReactMarkdown
                className="format-markdown"
                source={novelties.summary}
              />
              <div className="d-flex align-items-center flex-wrap mb-3">
                <h6 className="text-muted mb-0">
                  <small>Publicado: {novelties.date}</small>
                </h6>
                <div className="d-flex ml-auto">
                  <FacebookShareButton
                    url={shareUrl}
                    quote={novelties.title}
                    className="mr-1"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={shareUrl}
                    title={novelties.title}
                    className="mr-1"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <LinkedinShareButton url={shareUrl} className="mr-1">
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-10 col-lg-9">
                  {novelties.headerImages !=null  &&
                  <img
                    src={novelties.headerImages.image.url}
                    className="w-100 mb-4"
                    alt="novedad"
                  />
                  }
                </div>
              </div>
              <div className="text mb-3">
                <ReactMarkdown
                  className="format-markdown"
                  source={novelties.content}
                />
              </div>
              <div className="d-flex align-items-center flex-wrap py-4">
                <h4 className="flex-grow-1 flex-grow-1 mb-2">
                  COMPARTILO CON TU RED
                </h4>
                <div className="d-flex mb-2">
                  <FacebookShareButton
                    url={shareUrl}
                    quote={novelties.title}
                    className="mr-1"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={shareUrl}
                    title={novelties.title}
                    className="mr-1"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <LinkedinShareButton url={shareUrl} className="mr-1">
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </div>
        ))}
      </section>
    </Layout>
  )
}

export default NewsPage

export const newsQuery = graphql`
  query($id: String) {
    allStrapiNovelties(
      filter: { id: { eq: $id } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        content
        summary
        date(formatString: "DD MMMM, YYYY", locale: "es")
        novelties_categories {
          name
        }
        headerImages {
          image {
            id
            url
          }
        }
      }
    }
  }
`
